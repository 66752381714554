<template lang="pug">
  div(class="container-logo")
    img.stylelogo.d-block(:src="Logo" v-openlog)
    h5(
      v-if="message"
      style="position: absolute; margin-top: 30vh; text-align: center; color: firebrick;"
    ) No tienes habilitado el uso de la cámara y del video. Habilítalo y vuelve a intentarlo.
    div(
      v-if="messageTurn"
      style="position: absolute; margin-top: 30vh;"
    )
      span(style="text-align: center")
        button.btn.btn-info(style="width: 100%; display: inline-block;" @click="startTurn") Reintentar
      h5.mt-3(
        style="text-align: center; color: firebrick;"
      ) No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico.
    div(v-if="!message && !messageTurn" class="position-relative d-inline text-center")
      img(:src="Conected" class="size_icon mx-auto")
      h5 Estamos solicitando tu turno. Por favor espera.
</template>
<script>
import LogoColmedica from "@/assets/colmedica_logo.png";
import { mapActions, mapState } from "vuex";
import Conected from "@/assets/conected.png";

export default {
  name: "BeforeTurn",
  data: () => ({
    message: false,
    messageTurn: false,
    LogoColmedica,
    Conected: Conected,
    errorCounter: 15,
    turnInfo: null
  }),
  created() {
    this.startTurn();
  },

  computed: {
    ...mapState({
      company: state => state.company,
      company_queue: state => state.company_queue,
      this_user_data: state => state.client_data,
      turn: state => state.turn,
      tramite_info: state => state.tramite_info,
      catch_error: state => state.catch_error,
      env: state => state.env
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      getTurn: "getTurn",
      cancelTurn: "cancelTurn",
      cleanTurn: "cleanTurn",
      putLog: "log/put"
    }),
    async startTurn() {
      try {
        this.messageTurn = false;
        if (this.errorCounter <= 0) this.errorCounter = 15;
        let turnInfo;
        if (!this.turnInfo) {
          turnInfo = await this.getTurn();
          this.putLog({
            name: "BeforeTurn. Obtiene un turno nuevo."
          });
          this.turnInfo = turnInfo;
        } else {
          this.putLog({
            name: "BeforeTurn. Continúa con el turno existente."
          });
          turnInfo = this.turnInfo;
        }
        this.$router.push({ name: "Turno" });
      } catch (error) {
        console.error(error)
        // Bad data stored on LS
        if (error.message == "Invalid Tramite") {
          localStorage.clear();
          this.cancelTurn();
          // return window.history.back();
        }
        this.$rollbar.error("Turn request error", error);
        --this.errorCounter;
        if (this.errorCounter > 0) {
          setTimeout(() => this.startTurn(), 3000);
        } else {
          this.putLog({
            name:
              "BeforeTurn. Ha fallado la generación del turno en 15 intentos.",
            checked: false,
            message: error
          });
          this.messageTurn = true;
          this.cancelTurn();
          this.$rollbar.error("User cant take a turn after 3 tries");
        }
      }
    }
  }
};
</script>

<style scoped>
.container-logo {
  flex-direction: column;
}
.size_icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
  text-align: center;
  border-radius: 50%;
  margin: 20px 0;
}
</style>
